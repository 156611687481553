export const isBrowser = typeof window !== 'undefined';

export const getUrlParameter = (name) => {
  if (!isBrowser) {
    return null;
  }
  
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(window.location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};
