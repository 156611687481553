import { isBrowser } from './url';

const key = 'reset:isRegistered';

export const registerForReset = async () => {
  if (!isBrowser) {
    return;
  }
  localStorage.setItem(key, true);

  // trackRegistration();
};

export const isRegisteredForReset = () => {
  if (!isBrowser) {
    return false;
  }

  return localStorage.getItem(key) === 'true';
};

// const trackRegistration = () => {
//   try {
//     if (process.env.NODE_ENV !== 'production') {
//       return;
//     }

//     window.PostAffTracker.createAction('resetoptin');
//     window.PostAffTracker.register();
//   } catch (e) {
//     console.log(e);
//   }
// };
