import React, { useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';

import device from '../utils/device';

const Button = styled.button`
  background: ${({ theme }) => theme.colors.actionPrimary};
  color: ${({ theme }) => theme.colors.textLight};
  border: none;
  font-size: 1rem;
  height: 4rem;
  font-weight: 900;
  width: 100%;
  border-radius: 8px;
  font-family: Lato;
  cursor: pointer;

  @media ${device.desktop} {
    font-size: 2rem;
    height: 8rem;
  }
`;

const InputContainer = styled.div`
  margin: 1rem 0;

  @media ${device.desktop} {
    margin: 20px 0;
  }
`;

const Input = styled.input`
  font-family: 'Lato';
  font-size: 1rem;
  line-height: 3rem;
  width: 100%;
  text-align: center;
  border: none;
  box-sizing: border-box;

  ::placeholder {
    color: #a5a3a3;
  }

  @media ${device.desktop} {
    font-size: 2rem;
    height: 6rem;
  }
`;

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required('Your first name is required')
    .trim()
    .label('Your first name'),
  email: yup
    .string()
    .required('You must supply a valid email')
    .email('You must supply a valid email')
    .trim()
    .label('Your email'),
});

const FormError = ({ message }) => {
  if (!message) {
    return null;
  }

  return <div>{message}</div>;
};

const RegisterForm = () => {
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState([]);

  const hasError = (errorString) =>
    errors.find((error) =>
      error.toLowerCase().includes(errorString.toLowerCase())
    );

  return (
    <form
      accept-charset="UTF-8"
      action="https://xz684.infusionsoft.com/app/form/process/31340abd6af6db951689f03a030b2a32"
      class="infusion-form"
      id="inf_form_31340abd6af6db951689f03a030b2a32"
      method="POST"
    >
      <input
        name="inf_form_xid"
        type="hidden"
        value="31340abd6af6db951689f03a030b2a32"
      />
      <input name="inf_form_name" type="hidden" value="RESET opt in" />
      <input name="infusionsoft_version" type="hidden" value="1.70.0.419298" />
      <InputContainer className="infusion-field">
        <Input
          id="inf_field_FirstName"
          name="inf_field_FirstName"
          placeholder="Your first name:"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        {!!hasError('first name') && (
          <FormError message={hasError('first name')} />
        )}
      </InputContainer>
      <InputContainer className="infusion-field">
        <Input
          id="inf_field_Email"
          name="inf_field_Email"
          placeholder="Your email address:"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {!!hasError('email') && <FormError message={hasError('email')} />}
      </InputContainer>
      <Button
        type="submit"
        onClick={async (clickEvent) => {
          try {
            schema.validateSync(
              {
                email,
                firstName,
              },
              { abortEarly: false }
            );
          } catch (e) {
            console.log('g', e);
            clickEvent.preventDefault();
            setErrors(e.errors);
          }
        }}
      >
        Yes! I want to reset
        <br />
        my nervous system
      </Button>
    </form>
  );
};

export default RegisterForm;
